import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2024/4B/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2024/4B/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2024/4B/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2024/4B/4.jpg';






// import p7 from 'assests/Photos/ClassAssembly/6D/7.jpg';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly4C2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/class-assembly/4C/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/class-assembly/4C/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/class-assembly/4C/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/class-assembly/4C/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/class-assembly/4C/5.webp`;


  
  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1.4,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1.4,
      },
    {
      src: p3,
      source:p3,
      rows: 1,
      cols: 1.4,
    },
   
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1.4,
    },
    {
        src: p5,
        source: p5,
        rows: 1.7,
        cols: 2,
      }
     

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      INSPIRATIONAL PERSONALITIES
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 4B Date: 2-4 September 2024

        </Typography>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Strong personalities inspire others to believe in themselves and achieve greatness"

<br/>
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 4C presented their assembly on the topic, ‘Inspirational Personalities,’ from Monday, 2 September 2024 to Wednesday, 4 September 2024. The assembly began with a questionnaire that encouraged students to reflect on the qualities and contributions of various inspirational figures. This interactive segment set the tone for the assembly, sparking curiosity and admiration among the students followed by a short speech, highlighting the impact of these remarkable individuals on society and how their perseverance, courage and determination serve as a beacon of hope and motivation for all.
        <br></br>
        The assembly's highlight was a heartfelt dance performance, expressed as a tribute to these inspiring personalities, symbolising gratitude for their extraordinary contributions to the world. The dance was both graceful and powerful, resonating with the theme of the assembly. 
        <br></br>
        A melodious song followed, further celebrating the lives and legacies of these iconic figures. The assembly concluded with a sincere note of gratitude to our beloved Principal, reminding everyone to acknowledge and appreciate the inspiring people who have shaped our world and to strive to embody their values in our own lives. 

        <br></br>
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “BEAUTY ATTRACTS THE EYES BUT PERSONALITY CAPTURES THE HEART"
   <br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly4C2024;